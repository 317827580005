import React from 'react';
import PropTypes from 'prop-types';

/**
 * Mail Functional Component.
 */
export default function Mail({ className, color, width, height }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
        >
            <path
                d="M26.91 4H5.09C2.833 4 1 5.85 1 8.13v16.522c0 2.281 1.832 4.13 4.09 4.13h21.82c2.258 0 4.09-1.849 4.09-4.13V8.13C31 5.85 29.168 4 26.91 4zm.383 2.609c.157 0 .306.031.446.08l-11.413 9.05L4.913 6.69c.14-.049.29-.08.447-.08h21.933zm-.279 19.565H4.986c-.761 0-1.377-.612-1.377-1.368V9.87l11.53 9.164a1.379 1.379 0 0 0 1.721 0L28.391 9.87v14.936c0 .756-.616 1.368-1.377 1.368z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    );
}

Mail.displayName = 'Mail';

Mail.propTypes = {
    classes: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

Mail.defaultProps = {
    classes: '',
    color: '#ffffff',
    width: '32',
    height: '32',
};
