import React from 'react';
import PropTypes from 'prop-types';

/**
 * Linkedin Functional Component.
 */
export default function Linkedin({ className, color, width, height }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
        >
            <path
                d="M8.98 4.5A2.49 2.49 0 0 1 6.5 7a2.49 2.49 0 0 1-2.48-2.5C4.02 3.12 5.13 2 6.5 2a2.49 2.49 0 0 1 2.48 2.5zM9 9H4v16h5V9zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V25H28V14.869c0-7.88-8.922-7.593-11.018-3.714V9z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    );
}

Linkedin.displayName = 'Linkedin';

Linkedin.propTypes = {
    classes: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

Linkedin.defaultProps = {
    classes: '',
    color: '#ffffff',
    width: '32',
    height: '32',
};
