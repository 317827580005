import React from 'react';
import { withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import Label from 'components/label';
import Behance from 'components/svg/behance';
import Dribbble from 'components/svg/dribbble';
import Magnifier from 'components/svg/magnifier';
import { PROJECT_TYPES } from 'helpers/constants';

import './project.css';

/**
 * Project Functional Component.
 */
export default function Project({
    name,
    category,
    href,
    color,
    imageName,
    type,
}) {
    return (
        <article className="project">
            <a
                className="project__link"
                href={href}
                style={{
                    backgroundColor: color,
                    backgroundImage: `url(${withPrefix(
                        `/img/projects/${imageName}`
                    )})`,
                }}
                target="_blank"
                rel="noopener noreferrer"
                title={`Voir le projet ${name} sur ${type}`}
            >
                {type === 'behance' && <Behance width="34" height="34" />}
                {type === 'dribbble' && <Dribbble width="34" height="34" />}

                <div className="project__name">{name}</div>
                <div className="project__category">{category}</div>

                <div className="project-overlay">
                    <div className="project-overlay__content">
                        <Magnifier />
                        <div className="project-overlay__title">
                            <strong>Voir le projet {name}</strong> <br />
                            sur <span className="capitalize">{type}</span>
                        </div>
                    </div>
                </div>

                <div className="project__label-container">
                    <Label type={type} className="project__label" />
                </div>
            </a>
        </article>
    );
}

Project.displayName = 'Project';

Project.propTypes = {
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    imageName: PropTypes.string.isRequired,
    type: PropTypes.oneOf(PROJECT_TYPES).isRequired,
};
