import React from 'react';
import PropTypes from 'prop-types';

/**
 * ScrollDown Functional Component.
 */
export default function ScrollDown({ className, color, width, height }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 52"
        >
            <g fill="none" fillRule="evenodd">
                <g>
                    <rect
                        stroke={color}
                        strokeWidth="2"
                        x="1"
                        y="1"
                        width="12"
                        height="28"
                        rx="6"
                    />
                    <ellipse fill={color} cx="7" cy="8.5" rx="1.105" ry="1.5" />
                </g>
                <path
                    d="M7.5 41a.373.373 0 0 1-.291-.154L4.12 36.898a.634.634 0 0 1 0-.744c.16-.205.421-.205.582 0L7.5 39.73l2.797-3.576c.161-.205.422-.205.582 0 .161.206.161.539 0 .744l-3.088 3.948A.373.373 0 0 1 7.5 41z"
                    fill={color}
                    fillRule="nonzero"
                />
                <path
                    d="M7.5 52a.373.373 0 0 1-.291-.154L4.12 47.898a.634.634 0 0 1 0-.744c.16-.205.421-.205.582 0L7.5 50.73l2.797-3.576c.161-.205.422-.205.582 0 .161.206.161.539 0 .744l-3.088 3.948A.373.373 0 0 1 7.5 52z"
                    fill={color}
                    fillRule="nonzero"
                    opacity=".36"
                />
            </g>
        </svg>
    );
}

ScrollDown.displayName = 'ScrollDown';

ScrollDown.propTypes = {
    classes: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

ScrollDown.defaultProps = {
    classes: '',
    color: '#0081A3',
    width: '14',
    height: '52',
};
