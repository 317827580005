import React from 'react';
import { withPrefix } from 'gatsby';
import Logo from 'components/svg/logo';
import ScrollDown from 'components/svg/scrollDown';

import './header.css';

const currentYear = (new Date().getFullYear());
const experienceDuration = currentYear - 2009;

/**
 * Header Functional Component.
 */
export default function Header({ onScrollClick }) {
    return (
        <header className="header">
            <div className="header__wrapper appear-when-ready">
                <Logo className="header__logo" />

                <h1 className="title">Elodie Arete</h1>
                <h1 className="header__job blue-title">UI/UX Designer</h1>
                <p className="header__desc regular-text">
                    Depuis plus de { experienceDuration } ans, j’aime concevoir des interfaces
                    accessibles, intelligentes et qui améliorent le quotidien
                    des utilisateurs. Retrouvez une sélection de mes projets sur{' '}
                    <a
                        className="grey-link"
                        href="https://dribbble.com/elodiearete"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Voir plus de projets sur Dribbble"
                    >
                        Dribbble
                    </a>{' '}
                    ou sur{' '}
                    <a
                        className="grey-link"
                        href="https://www.behance.net/elodiearete"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Voir plus de projets sur Behance"
                    >
                        Behance
                    </a>
                    .
                </p>

                <a
                    className="header__resume-link button appear-when-ready"
                    href={withPrefix('/documents/cv-elodie-arete.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Télécharger mon cv au format PDF"
                >
                    Voir mon cv
                </a>

                <button
                    className="header__scroll-down"
                    onClick={event => {
                        onScrollClick();
                        event.currentTarget.blur();
                    }}
                >
                    <ScrollDown />
                </button>
            </div>
        </header>
    );
}

Header.displayName = 'Header';
