import React from 'react';
import PropTypes from 'prop-types';

/**
 * Dribbble Functional Component.
 */
export default function Dribbble({ className, color, width, height }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
        >
            <path
                d="M30.647 13c-.176-.882-.53-1.941-.882-2.824-.353-.882-.883-1.764-1.412-2.47-.53-.882-1.235-1.588-1.765-2.294-.706-.706-1.412-1.236-2.294-1.765-.882-.53-1.588-1.059-2.47-1.412-.883-.353-1.942-.706-2.824-.882C18.118 1.176 17.059 1 16 1s-1.941.176-3 .353c-1.059.176-1.941.53-2.824.882-.882.353-1.764.883-2.47 1.412-.882.53-1.588 1.235-2.294 1.765-.706.706-1.236 1.412-1.765 2.294-.706.706-1.059 1.588-1.412 2.47-.353.883-.706 1.765-.882 2.824C1.176 14.059 1 14.941 1 16s.176 2.118.353 3c.176.882.53 1.941.882 2.824.353.882.883 1.764 1.412 2.47.53.882 1.235 1.588 1.765 2.294.706.706 1.412 1.236 2.294 1.765.882.53 1.588 1.059 2.47 1.412.883.353 1.942.706 2.824.882C14.059 31 14.941 31 16 31s2.118-.176 3-.353c.882-.176 1.941-.53 2.824-.882.882-.353 1.764-.883 2.47-1.412.882-.53 1.588-1.235 2.294-1.765.706-.706 1.236-1.412 1.765-2.294.53-.882 1.059-1.588 1.412-2.47.353-.883.706-1.942.882-2.824.177-.882.353-1.941.353-3s-.176-1.941-.353-3zm-1.765 2.824c-.176 0-4.588-1.06-9-.353-.176-.177-.176-.353-.353-.706-.353-.706-.529-1.236-.882-1.941 5.118-2.118 7.059-5.118 7.059-5.118 1.941 2.294 3 5.118 3.176 8.118zM24.471 6.47s-1.942 2.823-6.706 4.588C15.647 7 13.176 3.824 13 3.47c.882-.177 1.941-.353 3-.353 3.176 0 6.176 1.235 8.47 3.353zM10.529 4.353c.177.176 2.471 3.53 4.765 7.412-6.176 1.588-11.47 1.588-11.823 1.588.882-3.882 3.529-7.235 7.058-9zM3.118 16v-.353c.176 0 6.53.177 13.235-1.765L17.412 16c-.177 0-.353 0-.53.176C10 18.471 6.471 24.647 6.471 24.647 4.353 22.353 3.118 19.353 3.118 16zM16 28.882c-3.176 0-6-1.058-8.118-3l.177.177s2.47-5.294 10.059-7.941c1.764 4.764 2.47 8.647 2.823 9.706-1.412.705-3.176 1.058-4.941 1.058zm7.235-2.294c-.176-.706-.882-4.588-2.47-9.176 4.059-.706 7.764.53 7.94.53-.529 3.705-2.646 6.705-5.47 8.646z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    );
}

Dribbble.displayName = 'Dribbble';

Dribbble.propTypes = {
    classes: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

Dribbble.defaultProps = {
    classes: '',
    color: '#ffffff',
    width: '32',
    height: '32',
};
