import React from 'react';
import Mail from 'components/svg/mail';
import Behance from 'components/svg/behance';
import Dribbble from 'components/svg/dribbble';
import Linkedin from 'components/svg/linkedin';

import './social.css';

/**
 * Social Functional Component.
 */
export default function Social() {
    return (
        <div className="social">
            <div className="title margin-top-double">
                Des questions ? Envie d’en savoir plus ?
            </div>
            <div className="margin-top-double">
                <a
                    className="social__link social__link--mail"
                    href="mailto:elodie.arete@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="M'envoyer un email"
                >
                    <Mail />
                </a>
                <a
                    className="social__link social__link--linkedin"
                    href="https://fr.linkedin.com/in/elodie-arete-b60ab553"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Voir mon profil LinkedIn"
                >
                    <Linkedin />
                </a>
                <a
                    className="social__link social__link--dribbblee"
                    href="https://dribbble.com/elodiearete"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Voir mes projets sur Dribbble"
                >
                    <Dribbble />
                </a>
                <a
                    className="social__link social__link--behance"
                    href="https://www.behance.net/elodiearete"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Voir mes projets sur Behance"
                >
                    <Behance />
                </a>
            </div>
        </div>
    );
}

Social.displayName = 'Social';
