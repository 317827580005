import React from 'react';
import PropTypes from 'prop-types';

/**
 * Magnifier Functional Component.
 */
export default function Magnifier({ className, color, width, height }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 106 107"
        >
            <g fill={color} fillRule="nonzero">
                <path d="M64 39.042a2 2 0 1 0 .001 4.002A2 2 0 0 0 64 39.042z" />
                <path d="M103.574 91.906L75.939 64.27c4.502-6.78 6.874-14.634 6.874-22.823C82.813 18.617 64.237.042 41.406.042 18.575.042 0 18.617 0 41.448 0 64.28 18.575 82.855 41.406 82.855c8.19 0 16.042-2.372 22.823-6.873l7.639 7.638.003.003 19.992 19.993a8.227 8.227 0 0 0 5.856 2.426c2.211 0 4.29-.862 5.854-2.425A8.223 8.223 0 0 0 106 97.76a8.223 8.223 0 0 0-2.426-5.855zM63.255 71.618a36.947 36.947 0 0 1-21.849 7.096c-20.548 0-37.265-16.717-37.265-37.266C4.14 20.9 20.858 4.183 41.406 4.183c20.549 0 37.266 16.717 37.266 37.265a36.946 36.946 0 0 1-7.096 21.85 37.446 37.446 0 0 1-8.32 8.32zm4.344 1.877a41.546 41.546 0 0 0 5.854-5.854l5.874 5.874a50.253 50.253 0 0 1-5.854 5.854l-5.874-5.874zm33.047 27.193a4.113 4.113 0 0 1-2.927 1.213 4.113 4.113 0 0 1-2.927-1.213L76.408 82.305a54.403 54.403 0 0 0 5.854-5.854l18.384 18.383a4.109 4.109 0 0 1 1.213 2.927 4.11 4.11 0 0 1-1.213 2.927z" />
                <path d="M41 8.042c-18.196 0-33 14.804-33 33 0 18.197 14.804 33 33 33 18.196 0 33-14.803 33-33 0-18.196-14.804-33-33-33zm0 61.875c-15.922 0-28.875-12.953-28.875-28.875S25.078 12.167 41 12.167 69.875 25.12 69.875 41.042 56.922 69.917 41 69.917z" />
                <path d="M64.864 32.194c-1.754-4.697-4.808-8.712-8.832-11.613-4.12-2.97-8.96-4.539-13.995-4.539-1.125 0-2.037.939-2.037 2.096s.912 2.095 2.037 2.095c8.372 0 16.017 5.411 19.024 13.465a2.04 2.04 0 0 0 1.902 1.344c.243 0 .49-.045.73-.14 1.05-.415 1.574-1.627 1.17-2.708z" />
            </g>
        </svg>
    );
}

Magnifier.displayName = 'Magnifier';

Magnifier.propTypes = {
    classes: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

Magnifier.defaultProps = {
    classes: '',
    color: '#FFFFFF',
    width: '76',
    height: '77',
};
