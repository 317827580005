import React from 'react';
import PropTypes from 'prop-types';

/**
 * Behance Functional Component.
 */
export default function Behance({ className, color, width, height }) {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
        >
            <path
                d="M14.682 8.056c.565.748.942 1.87.942 2.99 0 1.122-.377 2.244-.942 2.804-.376.561-.753.935-1.506 1.309.942.374 1.695.934 2.26 1.682.564.748.752 1.682.752 2.804 0 1.308-.188 2.243-.753 3.177a5.709 5.709 0 0 1-1.506 1.496c-.564.56-1.317.747-2.258.934-.942.187-1.883.187-2.824.187H0V6h9.6c2.447 0 4.141.748 5.082 2.056zM3.952 9.364v4.3h4.895c.941 0 1.506-.187 2.07-.561.377-.187.754-.748.754-1.496 0-.934-.377-1.495-1.13-1.869a7.065 7.065 0 0 0-2.259-.374h-4.33zm0 7.664v5.234h4.895c.941 0 1.506-.187 2.07-.374.942-.374 1.318-1.309 1.318-2.43 0-.935-.376-1.682-1.317-2.056-.565-.374-1.318-.374-2.07-.374H3.952zm24.283-5.607c.941.373 1.883 1.121 2.447 2.056.565.934.942 1.869 1.13 2.99.188.748.188 1.683.188 2.99H21.27c0 1.496.565 2.43 1.506 2.992.565.373 1.318.56 2.071.56.941 0 1.506-.187 2.07-.747.377-.187.565-.561.754-.935h3.953c-.189.935-.565 1.682-1.506 2.617C28.8 25.439 26.918 26 24.658 26c-1.882 0-3.576-.56-5.082-1.87-1.317-.934-2.07-2.99-2.07-5.607 0-2.43.753-4.486 2.07-5.794 1.318-1.308 3.012-2.056 5.271-2.056 1.13 0 2.259.374 3.388.748zM22.4 14.785a3.085 3.085 0 0 0-.941 2.243h6.588c0-.935-.376-1.682-.941-2.243-.565-.56-1.318-.748-2.259-.748-1.13-.187-1.882.187-2.447.748zm6.588-7.85h-8.47v2.43h8.47v-2.43z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    );
}

Behance.displayName = 'Behance';

Behance.propTypes = {
    classes: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

Behance.defaultProps = {
    classes: '',
    color: '#ffffff',
    width: '32',
    height: '32',
};
