import React from 'react';
import PropTypes from 'prop-types';
import Project from './project';

import './projects.css';

/**
 * Projects Functional Component.
 */
export default function Projects({ projects }) {
    return (
        <section className="projects">
            <h2 className="projects__title">Sélection de projets</h2>

            <div className="projects__list">
                {projects.map(({ id, ...props }) => (
                    <Project key={id} {...props} />
                ))}
            </div>
        </section>
    );
}

Projects.displayName = 'Projects';

Projects.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
};
