import React from 'react';
import PropTypes from 'prop-types';

/**
 * Logo Functional Component.
 */
export default function Logo({ className, mainColor }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 581.4 366.2"
            className={className}
        >
            <path
                fill={mainColor}
                d="M577.1 144c-.9 1.2-2.3 2.3-3.9 3.3C569 109.9 530.6 14.2 451.3 1.8c-3.5-.5-7-.3-10.3.9-63.8 23.2-45.8 105.1-29.3 133-28 54.8-71.2 170.6 156 28.5 1-.6 2-1.2 2.8-1.8.4-.2.7-.5 1.1-.7l.1-.1c18.1-12.4 5.4-17.6 5.4-17.6z"
            />
            <path
                fill="#FFF"
                d="M186.9 355.1c27.6-158.4-146.2-68-182.5-147.7-1.5.7-2.9 1.4-4.4 2.1 35 84.4 217.1-15.8 180.8 156.7 0 0 2.4-4.1 6.1-11.1zM468.2 88.2c-3.3 16 6.5 30.5 22 32.4 15.5 1.9 30.8-9.5 34.1-25.5 3.3-16-6.5-30.5-22-32.4-15.5-1.9-30.8 9.5-34.1 25.5z"
            />
            <path
                fill={mainColor}
                d="M501.9 93.4c-.9 4.6 1.9 8.8 6.3 9.4 4.4.6 8.6-2.6 9.5-7.1.9-4.6-1.9-8.8-6.3-9.4-4.3-.7-8.6 2.5-9.5 7.1z"
            />
            <path
                fill="#2F2722"
                d="M503.5 93.7c-.8 3.6 1.5 6.9 5 7.3 3.5.4 7-2.2 7.7-5.8.8-3.6-1.5-6.9-5-7.3-3.5-.4-7 2.2-7.7 5.8z"
            />
            <path
                fill="#FFF"
                d="M510.3 152.1s56.9 5.3 66.8-8.1c0 0 14.4 5.9-9.4 20.1 29-22.5-19.4-1.9-57.4-12zM503.5 93.7c-.8 3.6 11.2-5.3 7.7-5.8-3.5-.4-7 2.2-7.7 5.8z"
            />
            <path
                fill={mainColor}
                d="M350.59499533 78.4954297l23.09538.40315068-.2216456 12.69746-23.09538-.40315067z"
            />
            <path
                fill="#FFF"
                d="M346.55959497 91.02790006l30.79384.53753423-.14660025 8.39832-30.79384-.53753423z"
            />
            <path
                fill="#2F2722"
                d="M359.8 165.5h.2c.3.1.6.2.9.2.5 0 .9-.1 1.3-.3 0 2.1 0 1.3-1.2 38.4l18.5-38.7-7.9-65.2-19.2-.3-11.2 68.8 17.8 35.4.8-38.3z"
            />
            <circle fill={mainColor} cx="361" cy="162.4" r="3.2" />
            <path
                fill={mainColor}
                d="M3.6 205.6c35 84.4 217.8-13.6 181.5 158.8 0 0 195.5-337.5-181.5-158.8"
            />
            <g>
                <path
                    fill="#2F2722"
                    d="M313.7 116.7l-19.8 3.8h-.4c-37.8-24.4 3.1-51 3.1-51-.1.8-6.5 4.7 2.3 11 32.2 15.6 14.8 36.2 14.8 36.2z"
                />
                <path
                    fill={mainColor}
                    d="M314.2 119.4l-.5-2.7-20.2 3.9 1.5 7.9 20.2-3.9-1-5.2"
                />
                <path
                    fill="#FFF"
                    d="M295.00396 128.49172l20.22096-3.93048.53424 2.74848-20.22096 3.93048z"
                />
                <path
                    fill={mainColor}
                    d="M338.9 209.6l-34 6.6-3.7-3.6-5.6-81.3 20.2-3.9 25.2 77.4z"
                />
                <path
                    fill="#FFF"
                    d="M304.9565 216.1828l33.96336-6.60168 1.908 9.816-33.96336 6.60168z"
                />
            </g>
            <g>
                <path
                    fill="#FFF"
                    d="M219.11189 104.05469l23.47275-9.96285 3.43816 8.1004-23.47275 9.96285z"
                />
                <path
                    fill={mainColor}
                    d="M220.60026 112.92878l27.52295-11.68193 37.35092 87.9998-27.52295 11.68193z"
                />
                <path fill="#FFF" d="M261.5 203.8l23.5-10 .4-4.5-27.5 11.6z" />
                <path fill={mainColor} d="M276 216.1l7.8-3.3 1.2-19-23.5 10z" />
                <path fill="#2F2722" d="M283.8 223.6l-7.8-7.5 7.8-3.3z" />
            </g>
        </svg>
    );
}

Logo.displayName = 'Logo';

Logo.propTypes = {
    className: PropTypes.string,
    mainColor: PropTypes.string,
};

Logo.defaultProps = {
    className: 'logo',
    mainColor: '#39ADCA',
};
