import React from 'react';
import Layout from 'components/layout';
import Projects from 'components/projects';
import Social from 'components/social';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import Header from 'components/header';

import projectsList from 'projects.json';

let mainRef;

function scrollToMain() {
    if (!mainRef) {
        return;
    }

    scrollIntoView(mainRef, {
        scrollMode: 'if-needed',
        behavior: 'smooth',
        block: 'start',
    });
}

/**
 * IndexPage Functional Component.
 */
export default function IndexPage() {
    return (
        <Layout>
            <Header onScrollClick={scrollToMain} />
            <main className="main" ref={ref => (mainRef = ref)}>
                <Projects projects={projectsList} />
            </main>
            <Social/>
        </Layout>
    );
}

IndexPage.displayName = 'IndexPage';

IndexPage.propTypes = {};
