import React from 'react';
import PropTypes from 'prop-types';
import Behance from 'components/svg/behance';
import Dribbble from 'components/svg/dribbble';
import { PROJECT_TYPES } from 'helpers/constants';

import './label.css';

/**
 * Label Functional Component.
 */
export default function Label({ className, type }) {
    const classes = `label label--${type} ${className ? className : ''}`.trim();
    return (
        <div className={classes}>
            {type === 'behance' && <Behance width="34" height="34" />}
            {type === 'dribbble' && <Dribbble width="34" height="34" />}
        </div>
    );
}

Label.displayName = 'Label';

Label.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

Label.defaultProps = {
    type: PropTypes.oneOf(PROJECT_TYPES).isRequired,
};
